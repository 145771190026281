<template>
    <div class="project-create">
        <div class="manage-wrapper">
            <div class="project-create-container">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="创建报告" name="first">
                        <el-form ref="createForm" :model="form" label-width="180px" :rules="rules">
                            <el-form-item label="报告名称：" prop="title">
                                <el-input v-model="form.title" maxlength="100" show-word-limit style="width:400px">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="分类：" prop="type">
                                <el-radio-group v-model="form.type">
                                    <el-radio v-for="(type, index) in typeOptions" :key="index + type.label"
                                        :label="type.value">
                                        {{ type.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="类型：" prop="type2" v-if="form.type == '-1'">
                                <el-radio-group v-model="form.type2">
                                    <el-radio v-for="(type, index) in typeOptions[0].types" :key="index + type.label"
                                        :label="type.value">
                                        {{ type.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="选择周：" v-if="form.type != '-1'">
                                <el-select v-model="weekVal" placeholder="请选择周" style="margin-bottom: 10px;"
                                    @change="selectWeek">
                                    <el-option v-for="item in weekList" :key="item.cycleId + item.cycleName"
                                        :label="item.cycleName" :value="item.cycleId">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="报告角色：" prop="roleIds" required v-if="form.type != '-1'">
                                <el-checkbox :indeterminate="form.isIndeterminate" v-model="form.checkAll"
                                    @change="handleCheckAllChange">全选</el-checkbox>
                                <el-checkbox-group v-model="form.roleIds" @change="handleCheckedRolesChange">
                                    <el-checkbox v-for="role in rolesOptions" :label="role.id" :key="role.id">
                                        {{ role.label }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="模块1：" v-if="form.type == 3 || form.type == 0">
                                <el-checkbox v-model="form.checkPhase">我的观思问</el-checkbox>
                                <div v-if="form.checkPhase">
                                    观思问时间：<br>
                                    <el-date-picker v-model="form.phaseDateTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                    <br>
                                    提交时间：<br>
                                    <el-date-picker v-model="form.phaseSubmitteTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="模块2：" v-if="form.type == 3 || form.type == 0">
                                <el-checkbox v-model="form.checkQuest">我的作业</el-checkbox>
                                <div v-if="form.checkQuest">
                                    <el-transfer v-model="form.worksIds" :data="workList" :titles="['全部作业', '已选作业']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按作业名称搜索"
                                        :props="{ key: 'questId', label: 'questName' }">
                                        <template #default="{ option }">
                                            <el-tooltip :content="option.questName" placement="bottom" effect="light">
                                                <span class="transfer-item">{{ option.questName }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-transfer>
                                    作业时间：<br>
                                    <el-date-picker v-model="form.questDateTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期" @change="timeChange">
                                    </el-date-picker>
                                    <br>
                                    提交时间：<br>
                                    <el-date-picker v-model="form.questSubmitteTime" type="datetimerange"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="前测/后测报告：" v-if="form.type == '-1'">
                                <el-checkbox v-model="form.test">前测</el-checkbox>
                                <div v-if="form.test">
                                    <el-transfer v-model="form.testIds" :data="testList" :titles="['全部前测', '已选前测']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按前测名称搜索"
                                        :props="{ key: 'activityId', label: 'title' }">
                                        <template #default="{ option }">
                                            <el-tooltip :content="option.title" placement="bottom" effect="light">
                                                <span class="transfer-item">{{ option.title }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-transfer>
                                </div>
                            </el-form-item>
                            <el-form-item label="报告：" v-if="form.type == 1">
                                <el-checkbox v-model="form.checkReport">报告</el-checkbox>
                                <div v-if="form.checkReport">
                                    <el-transfer v-model="form.reportIds" :data="reportList" :titles="['已有报告', '已选报告']"
                                        :button-texts="['取消', '选择']" filterable filter-placeholder="按前测名称搜索"
                                        :props="{ key: 'id', label: 'title' }">
                                        <template #default="{ option }">
                                            <el-tooltip :content="option.title" placement="bottom" effect="light">
                                                <span class="transfer-item">{{ option.title }}</span>
                                            </el-tooltip>
                                        </template>
                                    </el-transfer>
                                </div>
                            </el-form-item>
                            <!-- 所属项目与用户组 -->
                            <UserGroup v-if="form.type == 3" :id="Number($route.params.id)" @userGroup="changeUserGroup"
                                :isBoutique="form.type">
                            </UserGroup>
                            <el-form-item label="AI模板：" prop="description" required>
                                <div class="flex-column">
                                    <upload @uploadFile="handleFileChange" :name="'identifier'" :text="'上传AI模板'"
                                        v-if="form.type == 5 || form.type == 6" />
                                    <el-select v-model="modelVal" placeholder="请选择模板"
                                        style="margin-bottom: 10px; width: 200px;" @change="selectChange" v-else>
                                        <el-option v-for="item in modelList" :key="item.templateType + item.projectId"
                                            :label="item.title" :value="item.templateType">
                                        </el-option>
                                    </el-select>
                                    <el-input class="textarea" type="textarea" :autosize="{ minRows: 7 }"
                                        placeholder="生成报告描述" v-model="form.description">
                                    </el-input>
                                </div>

                            </el-form-item>
                            <!-- 按钮组 -->
                            <el-form-item class="el-form-item-btns" label-width="0px">
                                <el-button @click="$router.push('/home/totalReport')">取消</el-button>
                                <el-button type="primary" @click="onSubmit">确定</el-button>
                            </el-form-item>
                            <br />
                            <br />
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import upload from './upload-file.vue';
export default {
    components: {
        upload
    },
    data() {
        return {
            activeName: "first",
            form: {
                projectId: null, //项目id
                title: null, // 报告名称
                type: null, // 报告类型  0周总结 1盟市总结 2教委总结 3个人总结
                type2: null, //
                roleIds: [], // 角色
                checkPhase: null, // 观思问模块选择  0未选择 1已选择
                phaseDateTime: [], // 观思问时间
                phaseSubmitteTime: [], // 观思问提交时间
                checkQuest: null, // 作业块选择  0未选择 1已选择
                userGroupIds: null, // 用户组id
                description: null, // AI描述
                checkAll: false,
                isIndeterminate: true,
                worksIds: [], // 穿梭框选择的作业id
                week: '',// 选择周
                test: false, // 模块3
                testIds: [], // 穿梭框选择的前测id
                questDateTime: [], // 作业时间
                questSubmitteTime: [],// 作业提交时间
                checkReport: null, // 报告模块选择 0未选择 1已选择
                reportIds: [], // 穿梭框选择的报告id
            },
            // 表单验证规则
            rules: {
                title: [{
                    required: true,
                    message: "请填写报告名称",
                    trigger: "blur"
                }],
                type: [
                    {
                        required: true,
                        message: "请选择报告分类",
                        trigger: "change"
                    }
                ],
                type2: [{
                    required: true,
                    message: "请选择报告类型",
                    trigger: "change"
                }],
                roleIds: [{
                    required: true,
                    message: "请选择报告角色",
                    trigger: "change"
                }],
                description: [{
                    required: true,
                    message: "请填写报告描述",
                    trigger: "blur"
                }]
            },
            rolesOptions: [],
            typeOptions: [],
            fileMd5List: [],
            // fileList: [],
            workList: [],
            modelVal: '',
            modelList: [],
            weekVal: '',
            weekList: [],
            intervalId: null,
            loading: null,
            testList: [],
            reportList:[]
        };
    },
    methods: {
        // 上传文件
        handleFileChange(md5List) {
            console.log('回调List', md5List)
            this.fileMd5List = md5List;
        },
        // 切换用户组
        changeUserGroup(data) {
            console.log(data);
            this.form.trancheId = data.trancheId;
            this.form.projectId = data.projectId;
            this.form.userGroupIds = data.userGroupArr;
            console.log(this.form);
        },
        handleCheckAllChange(val) {
            console.log(val)
            if (val) {
                this.rolesOptions.map(item => {
                    this.form.roleIds.push(item.id);
                })
            } else {
                this.form.roleIds = [];
            }
            this.form.isIndeterminate = false;
        },
        handleCheckedRolesChange(value) {
            // console.log(value);
            let checkedCount = value.length;
            this.form.checkAll = checkedCount === this.rolesOptions.length;
            this.form.isIndeterminate = checkedCount > 0 && checkedCount < this.rolesOptions.length;
        },
        // 提交表单
        async commitForm() {
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            let submitForm = {
                title: this.form.title,
                type: this.form.type == '-1' ? this.form.type2 : this.form.type,
                projectId: this.$store.state.user.currentProject.id,
                description: this.form.description,
                descriptionFiles: this.fileMd5List,
                questIds: this.form.checkQuest ? this.form.worksIds.toString() : '',
                modelId: this.modelVal,
                periodId: this.weekVal,
                surveyIds: this.form.test ? this.form.testIds.toString() : '',
                checkSurvey: this.form.test ? 1 : 0,
                phaseDateStart: this.form.phaseDateTime.length > 0 ? this.form.phaseDateTime[0] : '',
                phaseDateEnd: this.form.phaseDateTime.length > 0 ? this.form.phaseDateTime[1] : '',
                phaseSubmitteStart: this.form.phaseSubmitteTime.length > 0 ? this.form.phaseSubmitteTime[0] : '',
                phaseSubmitteEnd: this.form.phaseSubmitteTime.length > 0 ? this.form.phaseSubmitteTime[1] : '',
                questDateStart: this.form.questDateTime.length > 0 ? this.form.questDateTime[0] : '',
                questDateEnd: this.form.questDateTime.length > 0 ? this.form.questDateTime[1] : '',
                questSubmitteStart: this.form.questSubmitteTime.length > 0 ? this.form.questSubmitteTime[0] : '',
                questSubmitteEnd: this.form.questSubmitteTime.length > 0 ? this.form.questSubmitteTime[1] : '',
                checkReport: this.form.checkReport ? 1 : 0,
                reportIds:this.form.checkReport ? this.form.reportIds.toString():''
            }
            this.$set(submitForm, 'roleIds', this.form.roleIds.toString())
            this.$set(submitForm, 'checkPhase', this.form.checkPhase ? 1 : 0)
            this.$set(submitForm, 'checkQuest', this.form.checkQuest ? 1 : 0)
            if (this.form.type == 3) {
                submitForm.groupString = JSON.stringify(this.form.userGroupIds)
            }

            console.log(`%c创建报告参数:`, 'color: green; font-weight: bold;');
            console.log(submitForm)
            let resData = await this.$Api.Project.createReport(submitForm);
            if (resData.code == 200) {
                this.intervalId = setInterval(() => {
                    this.getStatus(resData.data.reportId, resData.data.rootMsgId)
                }, 1000);
            } else {
                this.loading.close();
                this.$notify.error({
                    title: "创建失败",
                    message: resData.msg
                });
            }
        },
        //创建报告
        async onSubmit() {
            this.$refs['createForm'].validate((valid) => {
                if (valid) {
                    this.commitForm();
                } else {
                    this.$message.error({
                        title: "错误",
                        message: "请正确填写数据"
                    });
                }
            })
        },
        // 获取报告类型Option
        async getReportOption() {
            let pramas = {
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportOption(pramas);
            this.typeOptions = resData.data.type;
            this.rolesOptions = resData.data.role;
        },
        // 获取穿梭框作业列表
        async listByProject() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
                startTime: this.form.questDateTime ? this.form.questDateTime[0] : '',
                endTime: this.form.questDateTime ? this.form.questDateTime[1] : ''
            }
            let resData = await this.$Api.Project.listByProject(data);
            resData.data.map(item => {
                item.disabled = false
            })
            this.workList = resData.data
        },
        // 筛选作业时间
        timeChange() {
            this.worksIds = [];
            this.listByProject()
        },
        selectChange(val) {
            this.form.description = (this.modelList.filter(item => item.templateType == val)[0].content).replace(/\\n/g, '\n');
        },
        async queryStageCycleList() {
            let data = {
                projectId: this.$store.state.user.currentProject.id
            }
            let resData = await this.$Api.kimi.queryStageCycleList(data);
            // console.log('获取周列表:', resData);
            this.weekList = resData.data
        },
        // 选择周
        selectWeek(data) {
            console.log('选择周:', data)
        },
        // 获取AI模板
        async reportTemplate() {
            let data = {
                projectId: this.$store.state.user.currentProject.id
            }
            let resData = await this.$Api.kimi.reportTemplate(data);
            // console.log('AI模板:', resData);
            this.modelList = resData.data
        },
        // 判断报告是否生成
        async getStatus(reportId, rootMsgId) {
            let data = {
                reportId
            }
            let resData = await this.$Api.kimi.getStatus(data);
            // console.log('查询状态:', resData);
            if (resData.code == 200 && resData.data) {
                this.loading.close();
                this.$notify.success({
                    title: "创建成功",
                });
                clearInterval(this.intervalId)
                if (this.form.type == 7) {
                    this.$router.push({
                        path: `/WjxReport?breadNumber=4`,
                        query: {
                            vid: this.form.testIds[0],
                            reportId,
                            rootMsgId
                        }
                    })
                } else {
                    this.$router.push({
                        path: `/manager/report/create_kimi?breadNumber=4`,
                        query: {
                            reportId: reportId,
                            rootMsgId: rootMsgId,
                            breadNumber: 4,
                            reportType: this.form.type,
                            isView: false
                        }
                    })
                }
            }
        },
        // 获取穿梭框前测列表
        async listAdmin() {
            let data = {
                projectId: this.$store.state.user.currentProject.id,
                page: 1,
                limit: 1000,
                queryKey: ''
            }
            let resData = await this.$Api.wjx.listAdmin(data);
            // console.log('前测列表:', resData);
            resData.data.records.map(item => {
                item.disabled = false
            })
            this.testList = resData.data.records;
        },
        // 获取穿梭框报告列表
        async getReportList() {
            let pramas = {
                page: 1,
                limit: 100,
                projectId: this.$store.state.user.currentProject.id,
            }
            let resData = await this.$Api.Project.getReportList(pramas);
            console.log('穿梭框报告列表', resData.data);
            this.reportList = resData.data.records
        }
    },
    created() { },
    mounted() {
        this.getReportOption();
        this.listByProject();
        this.queryStageCycleList();
        this.reportTemplate();
        this.listAdmin();
        this.getReportList();
    }
};
</script>

<style lang="less" scoped>
@import "../../manage/manage_project/less/manage-project.less";

/deep/ .el-radio-group {}

/deep/ .el-radio {
    margin-bottom: 10px;
}
</style>